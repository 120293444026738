import React from 'react'
const terminal_user = 'user'
const github_username = 'HenryNugraha'
const discord_usertag = 'Henry#1987'
//const discord_userid = ''
const email = 'hello@putuhn.xyz'

const projects = [
	'darkguy10/BotClient',
	'darkguy10/Shellplate',
	'darkguy10/Fakestream',
	'darkguy10/ControlPanelAPI.js',
	'darkguy10/Deb8M8',
	'darkguy10/EmojiSteal',
]

const links = [
	{
		name: 'Website',
		icon: 'fas fa-fw fa-desktop',
		link: 'https://hnawc.com/',
		description: 'hnawc.com',
	},
	{
		name: 'Arknights',
		icon: 'fas fa-fw fa-gamepad',
		link: 'https://www.krooster.com/network/lookup/Henry',
		description: 'Game Profile',
	},
	{
		name: 'Genshin Impact',
		icon: 'fas fa-fw fa-gamepad',
		link: 'https://act.hoyolab.com/app/community-game-records-sea/index.html?user_id=10754189',
		description: 'Game Profile',
	},
	{
		name: 'Clash of Clans',
		icon: 'fas fa-fw fa-gamepad',
		link: 'https://pixelcrux.com/Clash_of_Clans/Profile/App?tag=RR0CY2YG',
		description: 'Game Profile',
	},
	{
		name: 'Chess',
		icon: 'fas fa-fw fa-plus-square',
		link: 'https://chess.com/play/putuhn',
		description: 'Invite me for a match',
	},
	/*
	{
		name: 'Discord',
		link: `https://discord.com/users/${discord_userid}`,
		icon: 'fab fa-fw fa-discord',
		description: `${discord_usertag} | Add me as friend!`,
	},
	{
		name: 'E-mail',
		icon: 'fas fa-fw fa-at',
		link: `mailto:${email}`,
		description: 'Lets get in touch!',
	},
*/
]

const info = (
	<>
		<p>I was a <span className="highlighted">boy</span>,</p>
		<p>then a <span className="highlighted">gamer</span>,</p>
		<p>now a loving <span className="highlighted">husband</span>,</p>
		<p>and a caring <span className="highlighted">father</span>,</p>
		<p>of a curious <span className="highlighted">boy</span>,</p>
		<p>while still being a <span className="highlighted">gamer</span>.</p>

		<p>&nbsp;</p>

		<p>Type "<span className="highlighted">link</span>" to see my game profiles and let's play.</p>
	</>
)

export {
	terminal_user,
	github_username,
	discord_usertag,
//	discord_userid,
	email,
	projects,
	links,
	info,
}
