import React from 'react'
import styles from './commands.module.scss'
import { links, info } from '../config'
import { Commands, Command } from '../typings'
import ListElement from '../ListElement/ListElement'

const rawCommands: Command[] = [
	{
		name: 'help',
		icon: 'fas fa-fw fa-question-circle',
		description: 'List down all available commands',
		execute(app) {
			const { commands } = app.state
			return (
				<>
					Available commands:
					{[...commands.values()].map(
						({ icon, name, description }, key) => (
							<ListElement
								key={key}
								icon={icon}
								name={name}
								description={description}
								help
							/>
						)
					)}
				</>
			)
		},
	},
	{
		name: 'about',
		icon: 'fas fa-fw fa-info-circle',
		description: 'Show information about me',
		execute(app) {
			const { userDataLoaded, userData } = app.state
			if (!userDataLoaded)
				return <>putuhn.xyz: user data could not be fetched</>
			const { avatar_url, login, name, bio } = userData
			return (
				<div className={styles.infoWrapper}>
					<div className={styles.infoInner}>
						<img
							src={avatar_url}
							className={styles.avatar}
							alt="Avatar"
						/>
						<div className={styles.content}>
							<div className={styles.header}>
								<strong>{name}</strong>{' '}
								<span className="muted"></span>
							</div>
							<em className={styles.bio}>"ヘローワールド。"</em>
							<div className={styles.info}>{info}</div>
						</div>
					</div>

					<div className={styles.icons}>
						<i className="fas fa-fw fa-id-card-o"></i>
					</div>
				</div>
			)
		},
	},
/* 	{
		name: 'anime',
		icon: 'fas fa-tv',
		description: 'List of anime I would approve',
		execute(app) {
			const { animeList } = app.state
			if (!animeList)
				return <>putuhn.xyz: cannot connect to MyAnimeList server</>
			return (
				<>
					{projectData.map(
						({ name, html_url, description }: any, key: number) => (
							<ListElement
								key={key}
								icon={'fab fa-fw fa-github-alt'}
								name={name}
								link={html_url}
								description={description}
							/>
						)
					)}
				</>
			)
		},
	}, */
	{
		name: 'link',
		icon: 'fas fa-fw fa-link',
		description: 'Fetch my important links',
		execute() {
			return (
				<>
					{links.map(({ icon, name, link, description }, key) => (
						<ListElement
							key={key}
							icon={icon}
							name={name}
							link={link}
							description={description}
						/>
					))}
				</>
			)
		},
	},
	{
		name: 'clear',
		icon: 'fas fa-fw fa-eraser',
		description: 'Clear terminal screen',
		execute(app) {
			app.setState({
				...app.state,
				record: [],
			})
		},
	},
]
const commands: Commands = new Map(rawCommands.map(cmd => [cmd.name, cmd]))

export default commands
